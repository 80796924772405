import { Component } from "@angular/core";
import { MobileCompareHeroComponent } from "../../components/mobile-compare-hero/mobile-compare-hero.component";
import { CarouselComponent } from "../../components/carousel/carousel.component";
import { DisclaimerGraphicComponent } from "../../components/disclaimer-graphic/disclaimer-graphic.component";
import { FaqComponent } from "../../components/faq/faq.component";
import { BlogSectionComponent } from "../../../blog/components/blog-section/blog-section.component";
import { CarouselItemComponent } from "../../components/carousel-item/carousel-item.component";
import { PhoneCardComponent } from "../../../deal/components/phone-card/phone-card.component";
import { PhoneCarouselComponent } from "../../../deal/components/phone-carousel/phone-carousel.component";
import { SimCarouselComponent } from "../../../deal/components/sim-carousel/sim-carousel.component";
import { MemberBenefitsComponent } from "../../../user/components/member-benefits/member-benefits.component";
import { CmsCarouselComponent } from "../../components/cms-carousel/cms-carousel.component";

@Component({
    selector: "app-mobile-compare",
    standalone: true,
    templateUrl: "./mobile-compare.component.html",
    styleUrls: ["./mobile-compare.component.scss"],
    imports: [
        MobileCompareHeroComponent,
        MemberBenefitsComponent,
        CarouselComponent,
        DisclaimerGraphicComponent,
        FaqComponent,
        BlogSectionComponent,
        CarouselItemComponent,
        PhoneCardComponent,
        PhoneCarouselComponent,
        SimCarouselComponent,
        CmsCarouselComponent,
    ],
})
export class MobileCompareComponent {
}
