import { Injectable } from "@angular/core";
import { Action, Selector, State, StateContext } from "@ngxs/store";
import { tap } from "rxjs";
import { LoginUserAction, LogoutUserAction, SendResetPasswordAction } from "./user.actions";
import { Router } from "@angular/router";
import { MatSnackBar } from "@angular/material/snack-bar";
import { UserService } from "../../services/user.service";
import { routeNames } from "../../../app-routing.module";

export class UserStateModel {
    accessToken!: string;
    refreshToken!: string;
    requestPasswordMessage!: string;
    isError!: boolean;
}

const defaults = {
    accessToken: localStorage.getItem("accessToken") ?? "",
    refreshToken: localStorage.getItem("refreshToken") ?? "",
    requestPasswordMessage: "",
    isError: false,
};

@State<UserStateModel>({
    name: "user",
    defaults: defaults,
})
@Injectable()
export class UserState {
    constructor(
        private userService: UserService,
        private router: Router,
        private _snackBar: MatSnackBar,
    ) { }

    @Selector()
    static getLoginStatus(state: UserStateModel): boolean {
        return state.accessToken !== "" ?? false;
    }

    @Selector()
    static getRequestPasswordMessage(state: UserStateModel): string {
        return state.requestPasswordMessage;
    }

    @Selector()
    static getErrorStatus(state: UserStateModel): boolean {
        return state.isError;
    }

    @Action(LoginUserAction)
    loginUserAction({ patchState }: StateContext<UserStateModel>, action: LoginUserAction) {
        const { fromPage, user } = action;
        return this.userService.loginUser(user).pipe(
            tap({
                next: async (res): Promise<void> => {
                    const accessToken = res.access;
                    const refreshToken = res.refresh;
                    localStorage.setItem("accessToken", accessToken);
                    localStorage.setItem("refreshToken", refreshToken);
                    patchState({
                        accessToken,
                        refreshToken,
                    });

                    if (fromPage === routeNames.noCreditCheckPhones.fullPath()) {
                        await this.router.navigateByUrl(routeNames.getStarted.beforeYouStart.fullPath());
                    } else if (window.location.pathname.includes("login")) {
                        if (fromPage) {
                            await this.router.navigateByUrl(fromPage);
                        } else {
                            await this.router.navigateByUrl(routeNames.compare.mobile.fullPath());
                        }
                    } else {
                        await this.router.navigate(["/share-open-banking"], {
                            queryParams: { from: fromPage },
                        });
                    }
                },
                error: (err): void => {
                    let error = err.error;
                    if (err.error.error) {
                        error = err.error.error;
                    } else if (err.error.detail) {
                        error = err.error.detail;
                    }
                    this.openSnackBar(error);
                },
            }),
        );
    }

    @Action(LogoutUserAction)
    logoutUserAction({ patchState }: StateContext<UserStateModel>): void {
        this.userService.signOut();
        localStorage.removeItem("accessToken");
        localStorage.removeItem("refreshToken");
        localStorage.removeItem("wizardStartURL");
        localStorage.removeItem("returnURL");
        patchState({
            accessToken: "",
            refreshToken: "",
        });
    }

    @Action(SendResetPasswordAction)
    sendResetPassAction(
        { patchState }: StateContext<UserStateModel>,
        action: SendResetPasswordAction,
    ) {
        return this.userService.requestPasswordReset(action.email).pipe(
            tap({
                next: (res) => {
                    patchState({
                        requestPasswordMessage: res.message.en,
                        isError: false,
                    });
                },
                error: (err) => {
                    patchState({
                        requestPasswordMessage: err.error.message.en,
                        isError: true,
                    });
                },
            }),
        );
    }

    private openSnackBar(errorDetail: string): void {
        this._snackBar.open("Error:", errorDetail, {
            duration: 5000,
        });
    }
}
