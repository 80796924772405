<div class="relative">
    <section class="flex z-2" [ngClass]="className">
        <div class="grow flex flex-col md:flex-row gap-4 relative mx-5 lg:mx-16 w-full pb-8 lg:pb-16">
            <div class="md:mt-8 md:mb-4 w-full md:basis-2/3">
                <h1 class="mx-auto font-display font-black text-4xl md:text-7xl lg:text-8xl text-left hyphens-auto w-full">
                    Find your next phone deal.
                </h1>
                <p class="mt-4 md:my-6 text-xl md:text-2xl">
                    Built by people who've been rejected 🙄
                </p>
            </div>
            <div class="grow flex flex-col items-stretch justify-center gap-3 mb-4 md:my-10 py-2 md:basis-1/3">
                <home-cta-card
                    title="Find a Phone deal"
                    [href]="routes.bundle.selectPhone.fullPath()"
                    background="bg-green-gradient"
                    class="max-h-28"
                ></home-cta-card>

                <home-cta-card-external
                    title='Find a "No Credit Check" Phone deal'
                    [href]="routes.noCreditCheckPhones.fullPath()"
                    [isRouterLink]="true"
                    background="bg-pink-gradient-2"
                    class="max-h-28"
                ></home-cta-card-external>
            </div>
        </div>
    </section>

    <div
        class="absolute bg-transparent bg-repeat-x w-[102%] h-[170px] p-0 mr-0 my-0 -mx-[2%] border-none z-1 -bottom-[160px] overflow-hidden"
        style="background-image: url(assets/svg/drip.svg);"
    ></div>
</div>
