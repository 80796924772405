export const environment = {
    production: false,
    baseUrl: "https://5c2j0sx2jyottzrml1ogk.nogginhq.com/api",
    plaid_environment: "development",
    footer: {
        Instagram: "https://www.instagram.com/nogginhq/",
        LinkedIn: "https://www.linkedin.com/company/noggin-hq/",
        TikTok: "https://www.tiktok.com/@nogginhq",
    },
    subscribe: {
        preapproval: "",
    },
    getStarted: {
        typeformId: {
            getStarted: "01J96TSQ3GMXXDXDSBRBZ2V7A7"
        }
    }
};
